:root {
  margin: 0;
  font-size: 10px;
  font-family: 'AlmoniNeue';
  --primaryColor: #5750fa;
  --primaryLight: #5650fa3b;
  --primaryLightBackground: #ecebff;
  --primaryLightText: #6c68ba;
  --secondaryColor: #ffffff;
  --textColor: #383745;
  --subTextColor: #6d6d6d;
  --lightBackgroundColor: #ececf0;
  --shadowColor: #5e5d7e14;
  --hrLine: linear-gradient(90deg,
  #ffffff 0%,
  #7f7f9066 19%,
  #38374580 49%,
  #7f7f9066 81%,
  #ffffff 100%);
  --paleLavender: #DAD9FC;
}

@media only screen and (max-width: 1600px) {
  :root {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1366px) {
  :root {
    font-size: 8px;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    font-size: 10px;
  }
}

html {
  scroll-behavior: smooth;
}

html * {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
