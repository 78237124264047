@import '../../functions';

body {
  margin: 0 !important;
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: px-to-rem(7);
    height: px-to-rem(7);
  }

  &::-webkit-scrollbar-track {
    border-radius: px-to-rem(10);
    background-color: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    outline: 1px solid var(--scrollbar-track-color);
    border-radius: px-to-rem(10);
  }
}


.cc-Site {
  // width: 100vw;
  min-height: 100vh;
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: var(--base-font-family);

  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: px-to-rem(60);
    bottom: 0;
    pointer-events: none;
    position: absolute;
  }
  &::before {
    background-color: #f0f1f6;
  }
  &::after {
    box-shadow: 0 0 px-to-rem(7) var(--shadow-color) inset;
    z-index: 1;
  }
}

.cc-Site[data-is-mobile-view='1'] {
  background: #f0f1f6;
  padding: 0;

  &::before,
  &::after {
    content: none;
  }
}


.cc-Site[cc-data-lang='ar']{
  font-family: var(--base-font-family-ar);
}

.cc-Site[dir='rtl'] {
  &::before,
  &::after {
    right: px-to-rem(200);
    left: 0;
    border-top-right-radius: px-to-rem(6);
  }
}

.cc-Site[dir='ltr'] {
  &::before,
  &::after {
    left: px-to-rem(200);
    right: 0;
    border-top-left-radius: px-to-rem(6);
  }
}
